:root {
  --dark-mode-background: black;
  --dark-mode-text: lightgrey;
  --light-mode-background: lightgrey;
  --light-mode-text: black;

  --radius: 8px;

  /*--btn-width: 32px;*/
  --btn-height: 32px;
  --btn-clr: blue;
  --btn-clr-hover: rgb(0, 0, 125);

  --btn-clr-submit: green;
  --btn-clr-submit-hover: lightgreen;


  --input-clr: black;
  --input-plchld-clr: grey;
}

* {
  margin: 0;
  padding: 0;
  /*box-sizing: border-box;*/

  outline: none;

  font-family: sans-serif;
  font-size: 1.05rem;
  font-weight: normal;
}

h2 {
  font-weight: bolder;
  display: block;
  font-size: larger;
  height: 2rem;
  margin-top: auto;
  margin-bottom: auto;
}

body {
  margin: 0;
  overflow: hidden;
}

/* colors */
.light {
  background-color: var(--light-mode-background);
  color: var(--light-mode-text);
}

.dark {
  background-color: var(--dark-mode-background);
  color: var(--dark-mode-text);
}

/* main app */
.App {
  min-width: 100%;
  max-width: 1080px;
  min-height: 100vh;
}

/* NAVIGATION BAR */
.App-header {
  display: flex;

  min-height: 3rem;
  min-width: 100%;
  max-width: 1080px;

  gap: 1rem;
  margin-left: 1rem;

  align-items: center;
}

.App-header p {
  font-size: 18px;
  line-height: 160%;

  margin-left: auto;
  float: right;
}

.App-header p+div {
  margin-right: 32px;
  margin-left: 1rem;
}

.navButton {
  padding: 0.75rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;

  background-color: var(--btn-clr);
  color: lightgrey;
  border-radius: var(--radius);

  font-weight: bold;
  text-decoration: none;
}

.navButton:hover,
.navButton:focus {
  background-color: var(--btn-clr-hover);
}

#nav-links-list {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

#nav-links-list li {
  float: left;
  margin: 10px;
}

#nav-links-list p {
  margin-left: auto;
}

/* MAIN APP CONTENT */
.App-content {
  box-sizing: border-box;
  padding-top: 5vh;
  padding-left: 3vh;
  padding-right: 3vh;
  height: 100vh;
  overflow-y: auto;
}

/* FORM */
.formDiv {
  min-width: 30%;
  max-width: 450px;
  margin: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.25);
  border-radius: var(--radius);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

form {
  width: 100%;
}

label {
  display: block;

  width: 100%;

  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: left;

  font-weight: bold;
}

label.dark {
  color: darkgrey;
}

label.light {
  color: lightgrey;
}

input {
  display: block;
  width: 100%;

  margin-top: 0px;
  margin-bottom: 10px;

  border: none;
  border-radius: var(--radius);

  padding: 0.75em;
}

input[type="text"],
input[type="number"],
input[type="tel"] {
  color: black;
}

input:invalid {
  outline: 4px dashed red;
  outline-offset: 4px;
}

input::placeholder {
  color: var(--input-plchld-clr);
}

input[type="file"] {
  display: block;
  width: 100%;

  padding: 5px;

  border: none;
  border-radius: var(--radius);
}

.btnSubmit {
  display: block;
  height: 2.5rem;

  text-align: center;

  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  padding-left: 1rem;
  padding-right: 1rem;

  border-radius: var(--radius);
  border: 1px solid #006d3a;

  background-color: var(--btn-clr-submit);
  color: #fff;
}

.btnSubmit:hover,
.btnSubmit:focus {
  background-color: var(--btn-clr-submit-hover);
}

/* INSTITUTION */
.institution-rooms {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  border-top: solid grey 2px;
}


/* Additional stuff cross platform */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
